@use "sass:math";
// ***************** MIXINS ***************** //
// Description:
// Global mixins used in the project
// --------------------------------------------

@mixin iconfont-styles {
    font-family: $iconfont;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    color: inherit;
}

// ==================================================
// creates an outline on the text in the selected color
// ==================================================

@mixin outline-font($color) {
    text-shadow: -1px -1px 0 $color, 1px -1px 0 $color, -1px 1px 0 $color, 1px 1px 0 $color;
}

// ==================================================
// CSS specific to iOS devices
// ==================================================

@mixin ios-only {
    @supports (-webkit-touch-callout: none) {
        @content;
    }
}

// ==================================================
// CLEARFIX
// ==================================================

@mixin clearfix() {
    *zoom: 1;

    &::before,
    &::after {
        content: "";
        display: table;
    }

    &::after {
        clear: both;
    }
}

// How to use
// div { @include clearfix }

@mixin hide-text() {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@mixin input-placeholder($color, $font-size) {
    &::input-placeholder {
        color: $color;
        font-size: $font-size;
    }

    &:placeholder {
        color: $color;
        font-size: $font-size;
    }
}

// ==================================================
// Global Padding
// ==================================================

@mixin global-padding {
    padding-left: $padding-xl;
    padding-right: $padding-xl;

    @include mq($until-dt-min) {
        padding-left: $padding-l;
        padding-right: $padding-l;
    }

    @include mq($tablet-max) {
        padding-left: $padding-s;
        padding-right: $padding-s;
    }
}

@mixin global-negative-margin {
    margin-left: -$padding-xl;
    margin-right: -$padding-xl;

    @include mq($until-dt-min) {
        margin-left: -$padding-l;
        margin-right: -$padding-l;
    }

    @include mq($tablet-max) {
        margin-left: -$padding-s;
        margin-right: -$padding-s;
    }
}

// two trucate/ellipsis mixins:

@mixin line-clamp($lines: 2, $line-height: false) {
    overflow: hidden;
    display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;

    // Fallback for non-Webkit browsers
    // (won't show `…` at the end of the block)
    @if $line-height {
        max-height: $line-height * $lines * 1px;
    }
}

@mixin text-truncate() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// ==================================================
// ratio mixins
// ==================================================

@mixin ratio-prop-number($prop, $ratio: 1 1) {
    @if length($ratio) < 2 or length($ratio) > 2 {
        @warn "$ratio must be a list with two values.";
    }

    $height: math.percentage(math.div(nth($ratio, 2), nth($ratio, 1)));

    #{$prop}: $height;
}
// @include ratio-prop-number (margin-top, 100px 50px);
// @include ratio-prop-number (height, 100 50);

// ==================================================
// column calculator
// ==================================================

@mixin column-tile-calculator($colX: 3, $gutX: 2.75) {
    // needs a wrapper that is display flex and justify content flex start or end to have value
    // we count tags, never classes!
    // $col-amount = amount of columns
    // $gutter-width = percentage of gutter without percentage sign
    $col-amount: $colX;
    $gutter-width: $gutX;
    $gutter-amount: $col-amount - 1;
    $gutter-sum: math.div($gutter-width * $gutter-amount, $col-amount);
    $col-calc: math.percentage(math.div(1, $col-amount)) - $gutter-sum;
    $num: $col-amount;

    width: $col-calc;
    margin-bottom: $gutter-width + 0%;

    @while $num > 0 {
        // instead of margin-right: $gutter-width + 0%; we need to override other possible breakpoints with the same syntax
        &:nth-of-type(#{$num}n + #{$num}) {
            margin-right: $gutter-width + 0%;
        }

        $num: $num - 1;
    }

    &:nth-of-type(#{$col-amount}n + #{$col-amount}) {
        margin-right: 0;
    }
}
// example use:
// @include column-tile-calculator(3, 2.75);

// ==================================================
// Timeslots
// ==================================================

// Minutes * 2.5 for desktop; minutes * 1.7 for mobile

$calc-desktop: 2.5;
$calc-mobile: 1.7;
$times: (
    10: (
        calc(10 * #{$calc-desktop}) + "px",
        calc(10 * #{$calc-mobile}) + "px",
    ),
    15: (
        calc(15 * #{$calc-desktop}) + "px",
        calc(15 * #{$calc-mobile}) + "px",
    ),
    20: (
        calc(20 * #{$calc-desktop}) + "px",
        calc(20 * #{$calc-mobile}) + "px",
    ),
    25: (
        calc(25 * #{$calc-desktop}) + "px",
        calc(25 * #{$calc-mobile}) + "px",
    ),
    30: (
        calc(30 * #{$calc-desktop}) + "px",
        calc(30 * #{$calc-mobile}) + "px",
    ),
    35: (
        calc(35 * #{$calc-desktop}) + "px",
        calc(35 * #{$calc-mobile}) + "px",
    ),
    40: (
        calc(40 * #{$calc-desktop}) + "px",
        calc(40 * #{$calc-mobile}) + "px",
    ),
    45: (
        calc(45 * #{$calc-desktop}) + "px",
        calc(45 * #{$calc-mobile}) + "px",
    ),
    50: (
        calc(50 * #{$calc-desktop}) + "px",
        calc(50 * #{$calc-mobile}) + "px",
    ),
    55: (
        calc(55 * #{$calc-desktop}) + "px",
        calc(55 * #{$calc-mobile}) + "px",
    ),
    60: (
        calc(60 * #{$calc-desktop}) + "px",
        calc(60 * #{$calc-mobile}) + "px",
    ),
    65: (
        calc(65 * #{$calc-desktop}) + "px",
        calc(65 * #{$calc-mobile}) + "px",
    ),
    70: (
        calc(70 * #{$calc-desktop}) + "px",
        calc(70 * #{$calc-mobile}) + "px",
    ),
    75: (
        calc(75 * #{$calc-desktop}) + "px",
        calc(75 * #{$calc-mobile}) + "px",
    ),
    80: (
        calc(80 * #{$calc-desktop}) + "px",
        calc(80 * #{$calc-mobile}) + "px",
    ),
    85: (
        calc(85 * #{$calc-desktop}) + "px",
        calc(85 * #{$calc-mobile}) + "px",
    ),
    90: (
        calc(90 * #{$calc-desktop}) + "px",
        calc(90 * #{$calc-mobile}) + "px",
    ),
    95: (
        calc(95 * #{$calc-desktop}) + "px",
        calc(95 * #{$calc-mobile}) + "px",
    ),
    100: (
        calc(100 * #{$calc-desktop}) + "px",
        calc(100 * #{$calc-mobile}) + "px",
    ),
    105: (
        calc(105 * #{$calc-desktop}) + "px",
        calc(105 * #{$calc-mobile}) + "px",
    ),
    110: (
        calc(110 * #{$calc-desktop}) + "px",
        calc(110 * #{$calc-mobile}) + "px",
    ),
    115: (
        calc(115 * #{$calc-desktop}) + "px",
        calc(115 * #{$calc-mobile}) + "px",
    ),
    120: (
        calc(120 * #{$calc-desktop}) + "px",
        calc(120 * #{$calc-mobile}) + "px",
    ),
    125: (
        calc(125 * #{$calc-desktop}) + "px",
        calc(125 * #{$calc-mobile}) + "px",
    ),
    130: (
        calc(130 * #{$calc-desktop}) + "px",
        calc(130 * #{$calc-mobile}) + "px",
    ),
    135: (
        calc(135 * #{$calc-desktop}) + "px",
        calc(135 * #{$calc-mobile}) + "px",
    ),
    140: (
        calc(140 * #{$calc-desktop}) + "px",
        calc(140 * #{$calc-mobile}) + "px",
    ),
    145: (
        calc(145 * #{$calc-desktop}) + "px",
        calc(145 * #{$calc-mobile}) + "px",
    ),
    150: (
        calc(150 * #{$calc-desktop}) + "px",
        calc(150 * #{$calc-mobile}) + "px",
    ),
    155: (
        calc(155 * #{$calc-desktop}) + "px",
        calc(155 * #{$calc-mobile}) + "px",
    ),
    160: (
        calc(160 * #{$calc-desktop}) + "px",
        calc(160 * #{$calc-mobile}) + "px",
    ),
    165: (
        calc(165 * #{$calc-desktop}) + "px",
        calc(165 * #{$calc-mobile}) + "px",
    ),
    170: (
        calc(170 * #{$calc-desktop}) + "px",
        calc(170 * #{$calc-mobile}) + "px",
    ),
    175: (
        calc(175 * #{$calc-desktop}) + "px",
        calc(175 * #{$calc-mobile}) + "px",
    ),
    180: (
        calc(180 * #{$calc-desktop}) + "px",
        calc(180 * #{$calc-mobile}) + "px",
    ),
    185: (
        calc(185 * #{$calc-desktop}) + "px",
        calc(185 * #{$calc-mobile}) + "px",
    ),
    190: (
        calc(190 * #{$calc-desktop}) + "px",
        calc(190 * #{$calc-mobile}) + "px",
    ),
    195: (
        calc(195 * #{$calc-desktop}) + "px",
        calc(195 * #{$calc-mobile}) + "px",
    ),
    200: (
        calc(200 * #{$calc-desktop}) + "px",
        calc(200 * #{$calc-mobile}) + "px",
    ),
);

// ==================================================
// Spacing
// ==================================================

$spacings: (
    s: 25px,
    m: 50px,
    l: 75px,
    xl: 100px
);

@mixin spacing() {
    @each $prop, $position in (bottom: below, top: above) {
        @each $size, $margin in $spacings {
            .space-#{$position}-#{$size} {
                margin-#{$prop}: $margin;
            }
        }
    }
}

// ==================================================
// Scrollbar
// ==================================================

@mixin scrollbar($fg-color: $silver, $bg-color: transparent, $size: 6px) {
    // FF:
    scrollbar-color: $fg-color $bg-color;
    scrollbar-width: thin;

    // Chrome + Edge
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    &::-webkit-scrollbar-track {
        background-color: $bg-color;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $fg-color;
    }
}

// use:
// @include scrollbar;
// @include scrollbar();
// @include scrollbar($wild-sand, $white-smoke);

// hover behavior for blocks in checkout

@mixin block-hover {
    @include mq($hover) {
        &:hover {
            box-shadow: 0 4px 30px 0 rgb(146 146 146 / 65%);
        }
    }
}

@mixin image-hover($image: "img") {
    @include mq($hover) {
        #{$image} {
            transition: transform 0.2s ease-out;
        }

        &:hover {
            #{$image} {
                transform: scale(1.02);
            }
        }
    }
}

@mixin image-overlay() {
    &::after {
        background-image: linear-gradient(1deg, rgb(0 0 0 / 50%) 46.54%, rgb(0 0 0 / 0%) 76.23%);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
