// This component is used in multiple locations, so check before changing or (re)moving

.usp-tile-component {
    position: relative;
    z-index: 1;
}

.usp-tile-component__content-wrap {
    margin: 0 auto;
    max-width: 1200px;
    padding: 56px 16px 28px;
    overflow: hidden;

    @include mq($not-mobile-tablet-pt) {
        padding: 44px 40px;

        .usp-tile-component--presale & {
            padding-bottom: 88px;
        }
    }
}

.usp-tile-component--lineplay {
    &::before {
        background-image: url("../../../../img/svg/lineplay-usptile.svg");
        background-position: left top;
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
        height: 827px;
        left: calc(50% - 527px);
        position: absolute;
        top: 170px;
        width: 1600px;
        z-index: 0;

        @include mq($not-mobile) {
            background-size: cover;
            height: 1030px;
            top: 160px;
            left: calc(50% - 794px);
            right: 0;
        }
    }
}

.usp-tile-component__title,
.usp-tile-component__tile-title {
    span {
        display: block;
    }
}

.usp-tile-component__title {
    font-size: 32px;
    line-height: 1.2;
    margin-bottom: 0;
}

.usp-tile-component__main-text {
    p {
        margin-top: 16px;
    }
}

.usp-tile-component__main-text,
.usp-tile-component__readmore-text {
    width: 100%;

    @include mq($not-mobile-tablet-pt) {
        max-width: 628px;
    }

    p {
        font-size: 16px;
        line-height: 130%;
        color: black;
        margin-bottom: 12px;

        @include mq($not-mobile-tablet-pt) {
            margin-bottom: 16px;
        }
    }
}

.usp-tile-component__readmore-wrapper {
    margin-bottom: 16px;
    line-height: 0;

    &.collapsed {
        .usp-tile-component__readmore-text {
            display: none;
        }

        .usp-tile-component__readmore-button {
            &::before {
                content: attr(data-read-more);
            }
        }
    }

    .usp-tile-component__readmore-text {
        display: block;
    }
}

.usp-tile-component__readmore-button {
    display: inline-block;
    font-size: 16px;
    line-height: 130%;
    text-decoration: underline;
    text-transform: none;

    &::before {
        content: attr(data-read-less);
    }

    &:hover {
        @include mq($not-mobile-tablet-pt) {
            text-decoration: none;
        }
    }
}

.usp-tile-component__tile {
    overflow: hidden;
    margin-bottom: -60px;

    @include mq($not-mobile-tablet-pt) {
        &.swiper-slide {
            flex: 1 1 33%;
            max-width: 33%;
        }
    }

    a {
        overflow: hidden;
    }
}

.usp-tile-component__tile-content {
    position: relative;

    a & {
        @include image-hover(".usp-tile-component__tile-image");
    }
}

.usp-tile-component__tile-picture {
    display: flex;
    height: 100%;
}

.usp-tile-component__tile-image {
    width: 100%;
    height: 390px;
    object-fit: cover;
    object-position: top;
}

.usp-tile-component__tile-text-wrapper {
    height: 100%;
    top: -60px;
    margin: 0 8px;
    position: relative;
    box-shadow: 2px 2px 8px 0 rgb(0 0 0 / 16%);
}

.usp-tile-component__tile-text {
    display: flex;
    flex-direction: column;
    background-color: $white;
    height: 100%;
    min-height: 124px;
    padding: 16px 20px 24px;
    gap: 5px;
}

.usp-tile-component__swiper-wrapper {
    @include mq($not-mobile-tablet-pt) {
        gap: 0 68px;
    }
}

.usp-tile-component__swiper {
    position: relative;
    padding: 24px 0;

    @include mq($not-mobile-tablet-pt) {
        padding: 32px 0;
    }
}

.usp-tile-component__tile-title {
    font-size: 24px;
    line-height: 120%;
    margin: 0;
}

.usp-tile-component__tile-subtitle {
    font-family: $heading-pro-double-regular;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    text-transform: none;
    color: $nero;
    margin: 0;
}

.usp-tile-component__button-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 16px;
    z-index: 1;
}

.usp-tile-component__slider-navigation {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 32px auto 0;
    max-width: 300px;
    z-index: 1;

    @include mq($not-mobile-tablet-pt) {
        display: none;
    }

    .swiper-pagination {
        position: static;
    }

    .swiper-pagination-bullet {
        width: 20px;
        height: 4px;
        display: inline-block;
        border-radius: 3px;
        background: $white-smoke;
        opacity: 1;
        margin: auto 11px;
        transition: all 0.2s ease-in-out;
        cursor: pointer;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
        background: $safety-orange;
        width: 38px;
        height: 6px;
    }

    .arrow-prev,
    .arrow-next {
        cursor: pointer;

        &::before {
            @include iconfont-styles;

            content: svg(swiper-arrow);
            display: inline-block;
            position: relative;
            font-size: 16px;
            vertical-align: middle;
            line-height: initial;
            color: $nero;
            width: 16px;
            height: 16px;
            margin-right: 9px;
        }
    }

    .arrow-prev {
        &::before {
            transform: scale(-1, 1);
        }
    }

    .arrow-next {
        text-align: right;
    }

    .swiper-button-disabled {
        opacity: 0;
    }
}
