/** font family **/
@media only screen and (min-width: 768px) {
  /*! "$not-mobile" */
}
@media only screen and (min-width: 901px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (min-width: 1025px) {
  /*! "$not-mobile-tablet" */
}
@media only screen and (max-width: 1599px) {
  /*! "until-dt-max" */
}
@media only screen and (max-width: 1366px) {
  /*! "until-dt-med" */
}
@media only screen and (max-width: 1279px) {
  /*! "until-dt-min" */
}
@media only screen and (min-width: 1280px) {
  /*! "dt-min" */
}
@media only screen and (max-width: 1024px) {
  /*! "tablet-max" */
}
@media only screen and (min-width: 901px) and (max-width: 1024px) {
  /*! "tablet-ls" */
}
@media only screen and (max-width: 900px) {
  /*! "mobile-tablet-pt" */
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
  /*! "tablet-pt" */
}
@media only screen and (max-width: 767px) {
  /*! "$mobile-max" */
}
@media only screen and (max-width: 415px) {
  /*! "$mobile-pt" */
}
.usp-tile-component {
  position: relative;
  z-index: 1;
}

.usp-tile-component__content-wrap {
  margin: 0 auto;
  max-width: 1200px;
  padding: 56px 16px 28px;
  overflow: hidden;
}
@media only screen and (min-width: 901px) {
  .usp-tile-component__content-wrap {
    padding: 44px 40px;
  }
  .usp-tile-component--presale .usp-tile-component__content-wrap {
    padding-bottom: 88px;
  }
}

.usp-tile-component--lineplay::before {
  background-image: url("../../../../img/svg/lineplay-usptile.svg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 827px;
  left: calc(50% - 527px);
  position: absolute;
  top: 170px;
  width: 1600px;
  z-index: 0;
}
@media only screen and (min-width: 768px) {
  .usp-tile-component--lineplay::before {
    background-size: cover;
    height: 1030px;
    top: 160px;
    left: calc(50% - 794px);
    right: 0;
  }
}

.usp-tile-component__title span,
.usp-tile-component__tile-title span {
  display: block;
}

.usp-tile-component__title {
  font-size: 32px;
  line-height: 1.2;
  margin-bottom: 0;
}

.usp-tile-component__main-text p {
  margin-top: 16px;
}

.usp-tile-component__main-text,
.usp-tile-component__readmore-text {
  width: 100%;
}
@media only screen and (min-width: 901px) {
  .usp-tile-component__main-text,
  .usp-tile-component__readmore-text {
    max-width: 628px;
  }
}
.usp-tile-component__main-text p,
.usp-tile-component__readmore-text p {
  font-size: 16px;
  line-height: 130%;
  color: black;
  margin-bottom: 12px;
}
@media only screen and (min-width: 901px) {
  .usp-tile-component__main-text p,
  .usp-tile-component__readmore-text p {
    margin-bottom: 16px;
  }
}

.usp-tile-component__readmore-wrapper {
  margin-bottom: 16px;
  line-height: 0;
}
.usp-tile-component__readmore-wrapper.collapsed .usp-tile-component__readmore-text {
  display: none;
}
.usp-tile-component__readmore-wrapper.collapsed .usp-tile-component__readmore-button::before {
  content: attr(data-read-more);
}
.usp-tile-component__readmore-wrapper .usp-tile-component__readmore-text {
  display: block;
}

.usp-tile-component__readmore-button {
  display: inline-block;
  font-size: 16px;
  line-height: 130%;
  text-decoration: underline;
  text-transform: none;
}
.usp-tile-component__readmore-button::before {
  content: attr(data-read-less);
}
@media only screen and (min-width: 901px) {
  .usp-tile-component__readmore-button:hover {
    text-decoration: none;
  }
}

.usp-tile-component__tile {
  overflow: hidden;
  margin-bottom: -60px;
}
@media only screen and (min-width: 901px) {
  .usp-tile-component__tile.swiper-slide {
    flex: 1 1 33%;
    max-width: 33%;
  }
}
.usp-tile-component__tile a {
  overflow: hidden;
}

.usp-tile-component__tile-content {
  position: relative;
}
@media (hover: hover) and (pointer: fine) {
  a .usp-tile-component__tile-content .usp-tile-component__tile-image {
    transition: transform 0.2s ease-out;
  }
  a .usp-tile-component__tile-content:hover .usp-tile-component__tile-image {
    transform: scale(1.02);
  }
}

.usp-tile-component__tile-picture {
  display: flex;
  height: 100%;
}

.usp-tile-component__tile-image {
  width: 100%;
  height: 390px;
  object-fit: cover;
  object-position: top;
}

.usp-tile-component__tile-text-wrapper {
  height: 100%;
  top: -60px;
  margin: 0 8px;
  position: relative;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.16);
}

.usp-tile-component__tile-text {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  min-height: 124px;
  padding: 16px 20px 24px;
  gap: 5px;
}

@media only screen and (min-width: 901px) {
  .usp-tile-component__swiper-wrapper {
    gap: 0 68px;
  }
}

.usp-tile-component__swiper {
  position: relative;
  padding: 24px 0;
}
@media only screen and (min-width: 901px) {
  .usp-tile-component__swiper {
    padding: 32px 0;
  }
}

.usp-tile-component__tile-title {
  font-size: 24px;
  line-height: 120%;
  margin: 0;
}

.usp-tile-component__tile-subtitle {
  font-family: "HeadingProDouble-Regular", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  text-transform: none;
  color: #2d2d2d;
  margin: 0;
}

.usp-tile-component__button-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 16px;
  z-index: 1;
}

.usp-tile-component__slider-navigation {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 32px auto 0;
  max-width: 300px;
  z-index: 1;
}
@media only screen and (min-width: 901px) {
  .usp-tile-component__slider-navigation {
    display: none;
  }
}
.usp-tile-component__slider-navigation .swiper-pagination {
  position: static;
}
.usp-tile-component__slider-navigation .swiper-pagination-bullet {
  width: 20px;
  height: 4px;
  display: inline-block;
  border-radius: 3px;
  background: #efefef;
  opacity: 1;
  margin: auto 11px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.usp-tile-component__slider-navigation .swiper-pagination-bullet:first-child {
  margin-left: 0;
}
.usp-tile-component__slider-navigation .swiper-pagination-bullet:last-child {
  margin-right: 0;
}
.usp-tile-component__slider-navigation .swiper-pagination-bullet-active {
  opacity: 1;
  background: #fe7000;
  width: 38px;
  height: 6px;
}
.usp-tile-component__slider-navigation .arrow-prev,
.usp-tile-component__slider-navigation .arrow-next {
  cursor: pointer;
}
.usp-tile-component__slider-navigation .arrow-prev::before,
.usp-tile-component__slider-navigation .arrow-next::before {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  color: inherit;
  content: "\f136";
  display: inline-block;
  position: relative;
  font-size: 16px;
  vertical-align: middle;
  line-height: initial;
  color: #2d2d2d;
  width: 16px;
  height: 16px;
  margin-right: 9px;
}
.usp-tile-component__slider-navigation .arrow-prev::before {
  transform: scale(-1, 1);
}
.usp-tile-component__slider-navigation .arrow-next {
  text-align: right;
}
.usp-tile-component__slider-navigation .swiper-button-disabled {
  opacity: 0;
}